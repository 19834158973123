// import React from "react";
import CustomButton from "../CustomButton";
import { PricingCardStyled } from "./pricingCard.styled";

import React, { useContext, useEffect, useState } from "react";
// import { loadStripe } from '@stripe/stripe-js';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { themeList } from "../../contexts/ThemeContext/themeList";
import ThemeContext from "../../contexts/ThemeContext";
// import Ru from '../../assets/us.png'

const PricingCard = ({
  bloodState,
  planType,
  buttonType,
  price,
  list,
  link_usd,
  link_eur,
  id,
  productId_usd,
  productId_eur,
  image_url,
  monthlySel,
  monthlyPrice,
  productId_usd_monthly,
}) => {
  // eslint-disable-next-line
  const [isImageVisible, setIsImageVisible] = useState(false);

  const { t } = useTranslation();

  const handleMouseEnter = () => {
    setIsImageVisible(true);
  };

  const handleMouseLeave = () => {
    setIsImageVisible(false);
  };
  // const [isCheckoutOpen, setIsCheckoutOpen] = useState(false);
  const navigate = useNavigate();

  // const handleClose = () => setIsCheckoutOpen(false);
  // const handleCheckout = async () => {
  //   console.log("pricecardID=============", productId)
  //   const response = await fetch("/create-checkout-session", {
  //     method: "POST",
  //     headers: {
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify({ productId })
  //   });
  //   const data = await response.json();
  //   console.log("data", data)
  //   setClientSecret(data.clientSecret);
  //   console.log("clientSecret", data.clientSecret)
  //   window.location.href = `/checkout?clientSecret=${data.clientSecret}`;
  //   // window.location.href = '/checkout';
  //   setIsCheckoutOpen(true);
  // };

  // console.log("imageurl", image_url)
  const handleCheckout = () => {
    const continent = localStorage.getItem("MOFB_Continent");
    console.log("continent", continent);
    // if (continent === 'EU') {
    //   const productID = productId_eur
    //   navigate(`/checkout/${productID}`)

    // } else {
    //   const productID = productId_usd
    //   navigate(`/checkout/${productID}`)
    // }
    // const productID = productId_usd;
    const productID = monthlySel ? productId_usd_monthly : productId_usd;
    navigate(`/precheckoutform/${productID}`);
  };
  const { theme } = useContext(ThemeContext);

  return (
    <PricingCardStyled
      bloodState={bloodState}
      planType={planType}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      theme={theme}
    >
      {/* <FlagImage src = {image_url} alt = 'flag' width={40}></FlagImage> */}

      <img src={image_url} alt="flag" width={40} />

      <h3>{t(planType)}</h3>
      <h4>
        <sup>$</sup>
        {monthlySel ? (
          <>
            <span>{monthlyPrice}</span>
            <sub>/ {t("month")}</sub>
          </>
        ) : (
          <>
            <span>{price}</span>
            <sub>/ {t("year")}</sub>
          </>
        )}
      </h4>
      <ul>
        {list.map((item, idx) => (
          <li key={idx} style={{ borderBottom: "1px solid #DED8E1" }}>
            {/* {t(item)} */}
            {item.normal ? <span>{t(item.normal)}</span> : t(item)}
            <i>
              {item.italic && (
                <p style={{ fontStyle: "italic" }}>{t(item.italic)}</p>
              )}
            </i>
          </li>
        ))}
      </ul>
      <CustomButton
        type={buttonType}
        className="mb-4"
        onClick={() => handleCheckout()}
      >
        {t("Buy Now")}
      </CustomButton>
    </PricingCardStyled>
  );
};

export default PricingCard;
