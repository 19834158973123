import styled from "styled-components";
import { themeList } from "../../contexts/ThemeContext/themeList";

export const HomeSectionStyled = styled.section`
  @media (max-width: 767px) {
    text-align: center;
  }
  color: #fff;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all 0.5s;
    background-color: ${({ theme: { theme } }) =>
      theme === themeList.light ? "rgba(0, 0, 0, 0)" : "rgba(0, 0, 0, 0.5)"};
    z-index: 2;
  }
  .home-container {
    z-index: 3;
    h1 {
      font-size: 50px;
      font-weight: 700;
    }
    h2 {
      color: #eee;
      font-family: "Raleway", sans-serif;
      font-size: 25px;
      margin-bottom: 30px;
    }
  }
`;

export const Wrap = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Button = styled.button`
  width: 140px;
  height: 45px;
  font-family: "Roboto", sans-serif;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 500;
  color: ${({ theme: { theme } }) =>
    theme === themeList.light ? "var(--ds-dark-1)" : "var(--ds-light-0)"};
  background-color: ${({ theme: { theme } }) =>
    theme === themeList.light ? "var(--ds-light-7)" : "var(--ds-dark-2)"};
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;

  &:hover {
    background-color: #62e5b1;
    box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
    color: #fff;
    transform: translateY(-7px);
  }
`;
