import React from "react";
import SectionContainer from "../../components/SectionContainer";
import SectionHead from "../../components/SectionHead";
// import ContactInfoBlock from "./ContactInfoBlock";
import ContactMapFormBlock from "./ContactMapFormBlock";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const { t } = useTranslation();
  return (
    <SectionContainer id={"contact"} secName={"contact-sec"}>
      <SectionHead head={t("contact")} text={""} />
      {/* <ContactInfoBlock /> */}
      <ContactMapFormBlock />
    </SectionContainer>
  );
};

export default Contact;
