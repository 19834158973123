import styled from "styled-components";
import { themeList } from "../../contexts/ThemeContext/themeList";
import Dropdown from "react-bootstrap/Dropdown";

export const HeaderStyled = styled.header`
  position: absolute;
  top: 0px;
  width: 100% !important;
  backgroud: transparent;
  transition: all 1s;
  background-color: rgba(255, 255, 255, 0.5);

  box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 8px;
  z-index: 98;
  &.active {
    position: fixed;
    width: 100%;
    background-color: ${({ theme: { theme } }) =>
      theme === themeList.light ? "var(--ds-light-7)" : "var(--ds-dark-2)"};
    a {
      color: var(--ds-light-0);
    }
  }
  nav {
    padding: 0;
    //Brand
    .navbar-brand {
      color: var(--ds-green-1);
      font-size: 30px;
      font-weight: 700;
    }
    //NavLinksContainer
    .navbar-nav {
      //Default
      display: flex;
      @media (max-width: 991px) {
        display: none;
      }
      a {
        transition: 0.7s;
        font-size: 14px;
        cursor: pointer;
        @media (min-width: 992px) {
          margin-right: 8px;
          color: ${({ theme: { theme } }) =>
            theme === themeList.light
              ? "var(--ds-dark-1)"
              : "var(--ds-light-0)"};
          padding: 20px 15px;
          &:hover,
          &.active {
            // background-color: var(--ds-green-1);
            border-bottom: 5px solid var(--ds-green-1);
            color: ${({ theme: { theme } }) =>
              theme === themeList.light
                ? "var(--ds-dark-1)"
                : "var(--ds-light-0)"};
          }
        }
      }
      //Collapse
      &.nav-links-container {
        display: none;
        @media (max-width: 992px) {
          position: fixed;
          // margin: auto;
          // top: 0;
          // left: 0;
          // bottom: 0;
          // right: 10;
          z-index: 99;
          // width: calc(18vw - 40px);
          // height: calc(25vh - 40px);
          width: 35px
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          background-color: var(--ds-light-0);
          // box-shadow: rgba(0, 0, 0, 0.9) 7px 7px 7px 100px;
        }
        a {
          @media (max-width: 992px) {
            margin: 13px;
            padding: 0;
            color: var(--ds-dark-1);
            &:hover,
            &.active {
              color: var(--ds-green-1);
              background-color: none;
            }
          }
        }
        .close-icon {
          position: absolute;
          top: 20px;
          right: 20px;
          font-size: 25px;
          cursor: pointer;
        }
      }
    }

    .menu-icon {
      margin: 15px 0;
      font-size: 30px;
      cursor: pointer;
      color: ${({ theme: { theme } }) =>
        theme === themeList.light ? "var(--ds-dark-1)" : "var(--ds-light-0)"};
    }
  }
`;

export const CustomSelect = styled.select`
  transition: all 1s;
  height: 45px !important;
  margin: auto !important;
  margin-left: 10px !important;
  width: 180px;
  background-color: transparent;
  color: ${({ theme: { theme } }) =>
    theme === themeList.light ? "var(--ds-dark-1)" : "var(--ds-light-0)"};
  &:focus {
    border: 1px solid !important;
    box-shadow: none
      ${({ theme: { theme } }) =>
        theme === themeList.light
          ? "var(--ds-dark-1)"
          : "var(--ds-light-0)"} !important;
    outline: ${({ theme: { theme } }) =>
      theme === themeList.light ? "var(--ds-dark-1)" : "var(--ds-light-0)"};
  }
`;

// export const Select = styled.select`
//   min-width: 10em;
//   padding: 0.5em 1em;
//   background: transparent;
//   border: #444 solid;
//   border-radius: 1em;
//   color: inherit;
//   font: inherit;

//   &.solid {
//     background: transparent;
//   }
// `;

export const CustomDropdown = styled(Dropdown)`
  margin: auto;
`;

export const CustomDropdownToggle = styled(Dropdown.Toggle)`
  background-color: transparent;
  width: 140px;
  height: 38px;
  color: ${({ theme: { theme } }) =>
    theme === themeList.light
      ? "var(--ds-dark-1)"
      : "var(--ds-light-0)"} !important;
  border: 1px solid
    ${({ theme: { theme } }) =>
      theme === themeList.light ? "var(--ds-light-4)" : "var(--ds-light-0)"};
  &:hover {
    border: 1px solid
      ${({ theme: { theme } }) =>
        theme === themeList.light ? "var(--ds-dark-1)" : "var(--ds-light-0)"};
    color: ${({ theme: { theme } }) =>
      theme === themeList.light ? "var(--ds-dark-1)" : "var(--ds-light-0)"};
  }
`;

export const CustomDropdownMenu = styled(Dropdown.Menu)`
  background-color: white;
`;

export const FlagImage = styled.img`
  width: 22px;
  height: 22px;
`;

export const StyledDropdownItem = styled(Dropdown.Item)`
  background-color: white;
  color: var(--ds-dark-1) !important;
`;
