import { FaRegBuilding } from "react-icons/fa";
import { IoLockClosed, IoNewspaperOutline } from "react-icons/io5";
// import { IoServerOutline } from "react-icons/io5";
import { TbPaperclip, TbWorld } from "react-icons/tb";
import { IoChatbubbleOutline } from "react-icons/io5";
import { FaServer } from "react-icons/fa";
export const services = [
  {
    iconName: IoNewspaperOutline,
    title: "Business creation",
    text: "We create your offshore company in strategic jurisdictions.",
  },
  {
    iconName: FaRegBuilding,
    title: "Banks and financial institutions",
    text: "We facilitate the opening of accounts and financial services in the main offshore entities worldwide.",
  },
  {
    iconName: IoLockClosed,
    title: "Asset Protection",
    text: "Protect your funds and assets from lawsuits, garnishments and prying eyes.",
  },
  {
    iconName: TbPaperclip,
    title: "Confidentiality",
    text: "We maintain strict confidentiality and privacy standards for all our clients. We do not store sensitive data and only work with servers in favorable jurisdictions.",
  },
  // {
  //   iconName: IoChatbubbleOutline,
  //   title: "Corporate advice",
  //   text: "We provide professional advice for business expansion and asset protection.",
  // },
  {
    iconName: TbWorld,
    title: "Apostilles and translations",
    text: "We carry out apostilles and translations of all your company documents so that they have international validity, and we send them by postal mail to any part of the world.",
  },
  {
    iconName: FaServer,
    title: "Secure VPN servers",
    text: "We offer our own VPN servers in America, Europe and Asia, without registration, pre-configured and ready to use.",
  },
];
