import styled from "styled-components";
import { themeList } from "../../contexts/ThemeContext/themeList";

export const CardItemStyled = styled.div`
  height: 300px; /* Default height for small devices */

  @media (min-width: 768px) {
    height: 360px; /* Set the height for medium devices */
  }

  @media (min-width: 1399px) {
    height: 290px; /* Set the height for large devices */
  }
  transition: all 0.5s;
  text-align: ${(props) => {
    // console.log(props);
    return props.centeredState ? "center" : "start";
  }};
  padding: 25px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  &:hover {
    background-color: var(--ds-green-1);
    .card-icon-square {
      background-color: var(--ds-light-0);
      svg {
        color: var(--ds-green-1);
        transform: scale(1.2);
      }
    }
    .card-num,
    h4,
    p {
      color: var(--ds-light-0);
    }
  }
  .card-num {
    transition: all 0.5s;
    color: var(--ds-green-1);
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 15px;
  }
  .card-icon-square {
    transition: all 0.5s;
    margin-bottom: 15px;
    width: 60px;
    height: 60px;
    margin-inline: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--ds-green-1);
    z-index: 2;
    position: relative;
    svg {
      transition: all 0.5s;
      font-size: 28px;
      color: var(--ds-light-0);
    }
  }
  h4 {
    transition: all 0.5s;
    color: ${({ theme: { theme } }) =>
      themeList.light === theme ? "var(--ds-dark-1)" : "var(--ds-light-0)"};
    font-weight: 600;
  }
  p {
    transition: all 0.5s;
    color: var(--ds-light-1);
  }
`;
