import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { useParams, useSearchParams } from "react-router-dom";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const CheckoutForm = () => {
  const [error, setError] = useState(null);
  const { productID } = useParams();
  const searchParams = useSearchParams();
  const session_id = searchParams[0].get("session_id");

  console.log(productID, searchParams[0].get("session_id"));

  // useEffect(() => {
  //   if (productID) {
  //     // Create a Checkout Session as soon as the page loads
  //     fetch("/api/create-checkout-session", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({ productID }),
  //     })
  //       .then((res) => {
  //         if (!res.ok) {
  //           return res.text().then((text) => {
  //             console.error("Error creating checkout session:", text);
  //             setError(text);
  //           });
  //         }
  //         res.json().then((data) => {
  //           console.log(data);
  //           setsession_id(data.session_id);
  //         });
  //       })
  //       .catch((error) => {
  //         console.error("Error creating checkout session:", error);
  //         setError(error.message);
  //       });
  //   }
  // }, [productID]);

  if (error) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          width: "100vw",
          height: "100vh",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h1>Error</h1>
        <p>{error}</p>
        <a href="/">Go back to home</a>
      </div>
    );
  }

  return (
    <div id="checkout">
      {session_id ? (
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{ clientSecret: session_id }}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            width: "100vw",
            height: "100vh",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src="/assets/loading.gif"
            alt="loading"
            style={{ height: "5rem" }}
          />
          <h1>Loading...</h1>
        </div>
      )}
    </div>
  );
};

export default CheckoutForm;
