import React from "react";
import { FooterSquareStyled } from "./footer.styled";
import { FaEnvelope, FaPhone } from "react-icons/fa";
import Logo from "../../assets/Logo.png";
import { t } from "i18next";

const FooterSquare = () => {
  return (
    <FooterSquareStyled>
      <img
        src={Logo}
        alt="logo"
        style={{ width: "15%", marginBottom: "5px" }}
      />
      {/* <p className="mb-3">Address Pending</p> */}
      {/* <p className="mb-1">
        <FaPhone style={{ marginRight: "5px" }} />
        <a href="tel:+1xxxxxxxxxx" style={{ color: "var(--ds-green-1)" }}>
          +1 xxxx xxxx xx
        </a>
      </p> */}
      {/* <p className="mb-1">
        <FaEnvelope style={{ marginRight: "5px" }} />
        <a
          href="mailto:info@myoffshorebusiness.net"
          style={{ color: "var(--ds-green-1)" }}
        >
          info@myoffshorebusiness.net
        </a>
      </p> */}

      <h4 style={{ marginTop: "1rem", marginBottom: "-0.5rem" }}>
        {t("Payment Methods")}
      </h4>
      <ul>
        <li>
          {/* <FaCcVisa /> */}
          <img
            src="https://img.icons8.com/color/48/000000/visa.png"
            alt="Visa"
          />
        </li>
        <li>
          {/* <FaCcMastercard /> */}
          <img
            src="https://img.icons8.com/color/48/000000/mastercard.png"
            alt="Mastercard"
          />
        </li>
        <li>
          {/* <FaCcAmex /> */}
          <img
            src="https://img.icons8.com/color/48/000000/amex.png"
            alt="Amex"
          />
        </li>
        <li>
          {/* <FaGooglePay /> */}
          <img
            src="https://img.icons8.com/color/48/000000/google-pay.png"
            alt="Google Pay"
          />
        </li>
        <li>
          {/* <FaApplePay /> */}
          <img
            src="https://img.icons8.com/color/48/000000/apple-pay.png"
            alt="Apple Pay"
          />
        </li>
      </ul>

      <ul style={{ marginTop: "-1em" }}>
        <li>
          {/* Cashapp */}
          <img
            src="https://img.icons8.com/color/48/000000/cash-app.png"
            alt="Cashapp"
          />
        </li>
        <li>
          {/* Zelle */}
          <img
            src="https://img.icons8.com/color/48/000000/zelle.png"
            alt="Zelle"
          />
        </li>
        <li>
          {/* <FaStripe /> */}
          <img
            src="https://img.icons8.com/color/48/000000/stripe.png"
            alt="Stripe"
          />
        </li>
        <li>
          {/* Bank */}
          {/* <FaBuilding /> */}
          <img src="/assets/bank.png" alt="Bank" />
        </li>
        <li>
          {/* <FaBitcoin /> */}
          <img
            src="https://img.icons8.com/color/48/000000/bitcoin.png"
            alt="Bitcoin"
          />
        </li>
      </ul>
      <h4 style={{ marginTop: "1rem", marginBottom: "-0.5rem" }}>
        {t("Contact Us")}
      </h4>
      <ul>
        <li>
          {/* <FaFacebookF /> */}
          <img
            src="https://img.icons8.com/color/48/000000/facebook.png"
            alt="Facebook"
            onClick={() => {
              window.open("https://www.facebook.com/myoffshorebusiness/");
            }}
            style={{ cursor: "pointer" }}
          />
        </li>
        <li>
          {/* <FaInstagram /> */}
          <img
            src="https://img.icons8.com/color/48/000000/instagram-new.png"
            alt="Instagram"
            onClick={() => {
              window.open("https://www.instagram.com/myoffshorebusiness/");
            }}
            style={{ cursor: "pointer" }}
          />
        </li>
        <li>
          {/* <FaTelegram /> */}
          <img
            src="https://img.icons8.com/color/48/000000/telegram-app.png"
            alt="Telegram"
            onClick={() => {
              window.open("https://t.me/myoffshorebusiness");
            }}
            style={{ cursor: "pointer" }}
          />
        </li>
        <li>
          {/* <FaEnvelope /> */}
          <img
            src="https://img.icons8.com/color/48/000000/email.png"
            alt="Email"
            onClick={() => {
              window.open("mailto:info@myoffshorebusiness.com");
            }}
            style={{ cursor: "pointer" }}
          />
        </li>

        {/* <li>
          <FaTwitter />
        </li>
        <li>
          <FaLinkedinIn />
        </li> */}
      </ul>
      <p style={{ fontSize: "12px" }}>
        {t("The entirety of this site is protected by copyright")}{" "}
        <strong>&copy; {new Date().getFullYear()} My Offshore Business.</strong>
        <br /> {t("All rights reserved.")}
      </p>
    </FooterSquareStyled>
  );
};

export default FooterSquare;
