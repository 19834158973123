export const pricingPlans = [
  // {
  //   buttonType: "regular-pricing-btn",
  //   bloodState: false,
  //   image_url: "../../assets/Costa_rica.png",
  //   planType: "COSTA RICA",
  //   price: 0,
  //   list: [
  //     "Company ready in 7 days",
  //     "No taxes on income generated outside of Costa Rica",
  //     "Requires local accounting",
  //     "Multi-currency OR local bank account",
  //   ],
  //   // productId: "price_1ORUtHB1fdk2jWeOldbSk4le",
  //   productId_usd: "price_1OV3FxC8LlQ9FnFYNSatlMEa",
  //   productId_eur: "price_1ORUrACbCczEUrZJUlcCJwEn"
  // },
  {
    buttonType: "regular-pricing-btn",
    bloodState: false,
    image_url: "../../assets/Panama.png",
    planType: "PANAMA",
    price: 1800,
    monthlyPrice: 180,
    list: [
      "Company ready in 7 days",
      "All local fees included",
      "No taxes on income generated outside of Panama",
      "No accounting required",
      {
        normal: "Multi-currency bank account",
        italic: "+ USD 700 (one-time fee)",
      },
    ],
    productId_usd: "price_1PB6sgC8LlQ9FnFYhTyjW3Qg",
    // productId_eur: "price_1ORV6PCbCczEUrZJN4xghQn7",
    // link_usd: 'https://buy.stripe.com/test_dR6bL600QaGPeOsdQS',
    // link_eur: 'https://buy.stripe.com/test_14kcPa6pe8yH8q49AD'
    productId_usd_monthly: "XXX",
    bankFee: true,
    bankProductId: "price_1PCEGoC8LlQ9FnFYAKlzK80H",
  },
  {
    buttonType: "regular-pricing-btn",
    bloodState: false,
    image_url: "../../assets/Belize.png",
    planType: "BELIZE",
    price: 1600,
    monthlyPrice: 160,
    list: [
      "Company ready in 7 days",
      "No taxes on income generated outside of Belize",
      "No accounting required",
      {
        normal: "Multi-currency bank account",
        italic: "+ USD 700 (one-time fee)",
      },
      "All local fees included",
    ],
    productId_usd: "price_1PB6tSC8LlQ9FnFY5EONgxyg",
    // link_usd: "https://buy.stripe.com/test_eVa4iE7tiaGP6hWbIM",
    // link_eur: "https://buy.stripe.com/test_8wMbL67tibKT9u8eUZ",
    productId_usd_monthly: "XXX",
    bankFee: true,
    bankProductId: "price_1PCEGoC8LlQ9FnFYAKlzK80H",
  },
  // {
  //   buttonType: "regular-pricing-btn",
  //   bloodState: false,
  //   image_url: "../../assets/Nicaragua.png",
  //   planType: "NICARAGUA",
  //   price: 49,
  //   list: [
  //     "Company ready in 7 days",
  //     "No taxes on income generated outside of Nicaragua",
  //     "No accounting required",
  //     "Multi-currency bank account",
  //   ],
  //   productId_usd: "",
  // },
  // {
  //   buttonType: "regular-pricing-btn",
  //   bloodState: false,
  //   image_url: "../../assets/Guatemala.png",
  //   planType: "GUATEMALA",
  //   price: 89,
  //   list: [
  //     "Company ready in 7 days",
  //     "No taxes on income generated outside of Guatemala",
  //     "No accounting required",
  //     "Multi-currency bank account",
  //   ],
  //   productId_usd: "",
  // },
  // {
  //   buttonType: "regular-pricing-btn",
  //   bloodState: false,
  //   image_url: "../../assets/Honduras.png",
  //   planType: "HONDURAS",
  //   price: 129,
  //   list: [
  //     "Company ready in 7 days",
  //     "No taxes on income generated outside of Honduras",
  //     "No accounting required",
  //     "Multi-currency bank account",
  //   ],
  //   productId_usd: "",
  // },
  {
    buttonType: "regular-pricing-btn",
    bloodState: false,
    image_url: "../../assets/Dominican_Republic.png",
    planType: "DOMINICAN REPUBLIC",
    price: 1400,
    monthlyPrice: 140,
    list: [
      "Company ready in 30 days",
      "All local fees included",
      "No taxes on income generated outside of Dominican Republic",
      { normal: "Requires local accounting", italic: "included" },
      {
        normal: "Multi-currency bank account",
        italic: "+ USD 700 (one-time fee)",
      },
    ],
    productId_usd: "price_1PB6uIC8LlQ9FnFYmRy3WXUA",
    productId_usd_monthly: "XXX",
    bankFee: true,
    bankProductId: "price_1PCEGoC8LlQ9FnFYAKlzK80H",
  },
  {
    buttonType: "regular-pricing-btn",
    bloodState: false,
    image_url: "../../assets/samoa.png",
    planType: "AMERICAN SAMOA",
    price: 700,
    monthlyPrice: 70,
    list: [
      "Company with EIN ready in 15 days",
      { normal: "IRS and American Samoa fees and forms", italic: "included" },
      "No taxes on income generated outside of American Samoa",
      "No accounting required",
      { normal: "Bank account in the USA", italic: "included" },
    ],
    productId_usd: "price_1PB6uvC8LlQ9FnFY3ZQoZL0f",
    productId_usd_monthly: "XXX",
  },
  {
    buttonType: "regular-pricing-btn",
    bloodState: false,
    image_url: "../../assets/us.png",
    planType: "UNITED STATES (DELAWARE)",
    price: 900,
    monthlyPrice: 90,
    list: [
      "Company with EIN ready in 15 days",
      { normal: "IRS and Delaware state fees and forms", italic: "included" },
      "No taxes on income generated outside of United States",
      "No accounting required",
      { normal: "Bank account in the USA", italic: "included" },
    ],
    productId_usd: "price_1PB6vWC8LlQ9FnFYDKJx9UuV",
    productId_usd_monthly: "XXX",
  },
  {
    buttonType: "regular-pricing-btn",
    bloodState: false,
    image_url: "../../assets/us.png",
    planType: "UNITED STATES (WYOMING)",
    price: 700,
    monthlyPrice: 70,
    list: [
      "Company with EIN ready in 15 days",
      { normal: "IRS and Wyoming state fees and forms", italic: "included" },
      "No taxes on income generated outside of United States",
      "No accounting required",
      { normal: "Bank account in the USA", italic: "included" },
    ],
    productId_usd: "price_1PB6wFC8LlQ9FnFY5Jn0ZTjI",
    productId_usd_monthly: "XXX",
  },
];
