import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import translationES from "./assets/locales/es/default.json";
import translationPT from "./assets/locales/pt/default.json";
import translationDE from "./assets/locales/de/default.json";
import translationRU from "./assets/locales/ru/default.json";
import translationEN from "./assets/locales/en/default.json";
import translationIT from "./assets/locales/it/default.json";

// const storedLang = localStorage.getItem('i18nextLng');
// const detectionOptions = {
//     order: ['path', 'cookie', 'navigator', 'localStorage', 'subdomain', 'queryString', 'htmlTag'],
//     lookupFromPathIndex: 0

// }

const fallbackLng = ["en"];
const availableLanguages = ["en", "es", "ru", "pt", "de", "it"];
const resources = {
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  },
  pt: {
    translation: translationPT,
  },
  de: {
    translation: translationDE,
  },
  ru: {
    translation: translationRU,
  },
  it: {
    translation: translationIT
  }
};
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng,
    react: {
      useSuspense: false,
    },
    detection: {
      checkWhitelist: true,
    },
    debug: false,
    whitelist: availableLanguages,
  });
export default i18n;






// const apiKey = "24HMtyXUkwcQgQIbasgBkA";
// const loadPath = `https://api.i18nexus.com/project_resources/translations/{{lng}}/{{ns}}.json?api_key=${apiKey}`;

// i18next
//   .use(HttpBackend)
//   .use(LanguageDetector)
//   .use(initReactI18next)
//   .init({
//     fallbackLng: "en",
//     // detection: 'order',
//     ns: ["default"],
//     defaultNS: "default",
//     // detection: detectionOptions,
//     supportedLngs: ["en", "es", "pt", "de", "ru"],

//     backend: {
//       loadPath: loadPath,
//     },
//     react: {
//       useSuspense: true,
//     },
//     // storedLang,
//   });
