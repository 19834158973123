import React from "react";
// import {AnimationOnScroll} from "react-animation-on-scroll";
import { Col, Form, Row } from "react-bootstrap";
import PricingCard from "../../components/PricingCard/index";
import { pricingPlans } from "./pricingData";
const PricingBlock = () => {
  const [monthlySel, setMonthlySel] = React.useState(false);
  return (
    <div className="PricingBlock">
      <Row>
        {/* <div
          style={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "1.5rem",
          }}
        >
          <Form.Label style={{ color: monthlySel ? "var(--ds-green-1)" : "" }}>
            Monthly
          </Form.Label>
          <style type="text/css">
            {`
              #custom-switch:checked {
                background-color: var(--ds-green-1);
                border-color: var(--ds-green-1);
              }
            `}
          </style>
          <Form.Switch
            type="switch"
            id="custom-switch"
            label=""
            defaultChecked={!monthlySel}
            value={!monthlySel}
            onChange={() => setMonthlySel(!monthlySel)}
          />
          <Form.Label style={{ color: !monthlySel ? "var(--ds-green-1)" : "" }}>
            Yearly
          </Form.Label>
        </div> */}
        {pricingPlans.map((item, idx) => (
          <Col sm={12} md={6} lg={4} className="text-center my-2" key={idx}>
            {/* <AnimationOnScroll
              animateIn={`${"animate__fadeIn"
            }`}
            > */}
            <PricingCard {...item} id={idx} monthlySel={monthlySel} />
            {/* </AnimationOnScroll> */}
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default PricingBlock;
