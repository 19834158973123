import React from "react";
import SectionContainer from "../../components/SectionContainer";
import SectionHead from "../../components/SectionHead";
import ServicesBlock from "./ServicesBlock";
import { useTranslation } from "react-i18next";

const Services = () => {
  const { t } = useTranslation();
  return (
    <SectionContainer id={"services"} secName={"services-sec"}>
      <SectionHead head={t("services")} text={""} />
      <ServicesBlock />
    </SectionContainer>
  );
};

export default Services;
