import React from "react";
//Layout
// import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
//Sections
import Home from "../../sections/Home";
// import About from "../../sections/About";
import Services from "../../sections/Services";
// import Portfolio from "../../sections/Portfolio";
import Pricing from "../../sections/Pricing";
// import Team from "../../sections/Team";
import Contact from "../../sections/Contact";
// import ToggleThemeButton from "../../components/ToggleThemeButton";
// import Stripe from "../../components/Stripe/Stripe";
// import Payment from "../../components/payment";
// import Pay from "../../components/payment/pay";

const Landing = () => {

  return (
    <>
      {/* <Header /> */}
      <main>
        <Home />
        {/* <About /> */}
        <Services />
        {/* <Portfolio /> */}
        <Pricing />
        {/* <div className="App">
      {showForm ? <Stripe/> : <><h3>$100.00</h3> <button onClick={()=>setShowForm(true)}>BUY</button></>}
    </div> */}
    {/* <Pay /> */}
        {/* <Team /> */}
        <Contact />
      </main>
      {/* <ToggleThemeButton /> */}
      <Footer />
    </>
  );
};

export default Landing;
