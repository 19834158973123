import React from "react";
import { useParams } from "react-router-dom";
import { pricingPlans } from "../../sections/Pricing/pricingData";

export default function PreCheckoutForm() {
  const { productID } = useParams();
  const plan = pricingPlans.filter(
    (plan) => plan.productId_usd === productID
  )[0];
  const [bankSelected, setBankSelected] = React.useState("true");

  const handleSubmit = async (e) => {
    await e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const data = Object.fromEntries(formData);
    fetch("/api/submit-details", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            window.location.href = `/checkout/${plan.productId_usd}?session_id=${data.clientSecret}`;
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("An error occurred. Please try again later.");
        window.location.href = "/";
      });
  };

  if (!productID || plan === undefined) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          alignItems: "center",
          justifyContent: "center",
          width: "100vw",
          height: "100vh",
        }}
      >
        <h1>Product ID not found</h1>
      </div>
    );
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        alignItems: "center",
        justifyContent: "center",
        margin: "2rem",
        padding: "2rem",
      }}
    >
      <style>
        {`
          label {
            font-weight: 600;
            margin-top: 0.25rem;
            margin-bottom: 0.5rem;
          }

          input {
            padding: 0.5rem;
            border-radius: 0.5rem;
            border: 1px solid #ccc;
          }
        `}
      </style>
      <h1>Business Information</h1>
      <div>
        <h2
          style={{
            textAlign: "center",
            fontSize: "1.5rem",
          }}
        >
          Buying for{" "}
          <span
            style={{
              color: "var(--ds-green-1)",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            {plan.planType}
          </span>
        </h2>
      </div>
      <form
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          width: "100%",
          maxWidth: "95%",
        }}
      >
        <input type="hidden" name="priceId" value={plan.productId_usd} />
        <input
          type="hidden"
          name="bankFee"
          value={plan.bankFee && bankSelected === "true"}
        />
        <input type="hidden" name="bankProductId" value={plan.bankProductId} />
        <input type="hidden" name="planType" value={plan.planType} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <label htmlFor="name">Company Name</label>
          <input
            style={{
              padding: "0.5rem",
              borderRadius: "0.5rem",
              border: "1px solid #ccc",
              width: "100%",
            }}
            type="text"
            placeholder="Your Company"
            id="name"
            name="name"
            required
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <label htmlFor="purpose">Business Purpose</label>
          <input
            style={{
              padding: "0.5rem",
              borderRadius: "0.5rem",
              border: "1px solid #ccc",
              width: "100%",
            }}
            type="text"
            placeholder="Business Purpose"
            id="purpose"
            name="purpose"
            required
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <label htmlFor="bank">Bank Account required?</label>
          <select
            style={{
              padding: "0.5rem",
              borderRadius: "0.5rem",
              border: "1px solid #ccc",
              width: "100%",
            }}
            id="bank"
            name="bank"
            required
            value={bankSelected}
            onChange={(e) => setBankSelected(e.target.value)}
          >
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
          <small>Do you need a bank account for your business?</small>
          {plan.bankFee && (
            <small style={{ color: "red" }}>
              If you choose "Yes", a fee of $700 will be added to your total
            </small>
          )}
        </div>
        <h2 style={{ textAlign: "center" }}>Member Information</h2>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <label htmlFor="firstname">First Name</label>
          <input
            style={{
              padding: "0.5rem",
              borderRadius: "0.5rem",
              border: "1px solid #ccc",
              width: "100%",
            }}
            type="text"
            placeholder="John"
            id="firstname"
            name="firstname"
            required
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <label htmlFor="lastname">Last Name</label>
          <input
            style={{
              padding: "0.5rem",
              borderRadius: "0.5rem",
              border: "1px solid #ccc",
              width: "100%",
            }}
            type="text"
            placeholder="Doe"
            id="lastname"
            name="lastname"
            required
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <label htmlFor="nationality">Nationality</label>
          <input
            style={{
              padding: "0.5rem",
              borderRadius: "0.5rem",
              border: "1px solid #ccc",
              width: "100%",
            }}
            type="text"
            placeholder="American"
            id="nationality"
            name="nationality"
            required
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <div>
            <label htmlFor="address">Address</label>
            <input
              style={{
                padding: "0.5rem",
                borderRadius: "0.5rem",
                border: "1px solid #ccc",
                width: "100%",
              }}
              type="text"
              placeholder="1234 Main St"
              id="address"
              name="address"
              required
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                width: "100%",
                justifyContent: "space-between",
                alignContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "48%",
                }}
              >
                <label htmlFor="city">City</label>
                <input
                  style={{
                    padding: "0.5rem",
                    borderRadius: "0.5rem",
                    border: "1px solid #ccc",
                    // width: "100%",
                  }}
                  type="text"
                  placeholder="Miami"
                  id="city"
                  name="city"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "48%",
                }}
              >
                <label htmlFor="zipcode">Zip Code</label>
                <input
                  style={{
                    padding: "0.5rem",
                    borderRadius: "0.5rem",
                    border: "1px solid #ccc",
                    // width: "100%",
                  }}
                  type="text"
                  placeholder="33101"
                  id="zipcode"
                  name="zipcode"
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <label htmlFor="country">Country</label>
              <input
                style={{
                  padding: "0.5rem",
                  borderRadius: "0.5rem",
                  border: "1px solid #ccc",
                  width: "100%",
                }}
                type="text"
                placeholder="United States"
                id="country"
                name="country"
              />
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <label htmlFor="email">Email</label>
          <input
            style={{
              padding: "0.5rem",
              borderRadius: "0.5rem",
              border: "1px solid #ccc",
              width: "100%",
            }}
            type="email"
            placeholder="johndoe@email.com"
            id="email"
            name="email"
            required
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <label htmlFor="phone">Phone</label>
          <input
            style={{
              padding: "0.5rem",
              borderRadius: "0.5rem",
              border: "1px solid #ccc",
              width: "100%",
            }}
            type="tel"
            placeholder="+1 234 567 8901"
            id="phone"
            name="phone"
            required
          />
        </div>
        <button
          style={{
            padding: "0.5rem",
            borderRadius: "0.5rem",
            border: "1px solid #ccc",
            width: "100%",
            backgroundColor: "var(--ds-green-1)",
            color: "white",
          }}
          type="submit"
        >
          Submit
        </button>
      </form>
    </div>
  );
}
