import React from "react";
import SectionContainer from "../../components/SectionContainer";
import SectionHead from "../../components/SectionHead";
import PricingBlock from "./PricingBlock";
import { useTranslation } from "react-i18next";

const Pricing = () => {
  const { t } = useTranslation();
  return (
    <SectionContainer id={"pricing"} secName={"pricing-sec"}>
      <SectionHead
        head={t("pricing")}
        // text={
        //   "Sit sint consectetur velit quisquam cupiditate impedit suscipit alias"
        // }
      />
      <PricingBlock />
    </SectionContainer>
  );
};

export default Pricing;
