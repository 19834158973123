import React from "react";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

const ConfirmationMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Set the height to 100% of the viewport height for vertical centering */
  width: 100%; /* Set the width to 100% for responsiveness */

  p {
    text-align: center; /* Center the text horizontally */
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 20px; /* Add padding for better readability */
  }
`;

const Faild = () => {
  const navigate = useNavigate();

  const onFaild = () => {
    navigate("/");
  };

  return (
    <ConfirmationMessageContainer>
      <section>
        <p
          style={{
            boxShadow: " rgba(0,0,0,0.24) 0px 3px 8px",
            maxWidth: "500px",
          }}
        >
          <div>
            The payment failed or was canceled. Please try again. If you believe
            there has been an error on our side, please contact us at:{" "}
            <a href="mailto:info@myoffshorebusiness.net">
              info@myoffshorebusiness.net
            </a>
          </div>
          <Button
            className="mt-4"
            style={{ backgroundColor: "#ff5722", border: "none" }}
            onClick={() => onFaild()}
          >
            Faild
          </Button>
        </p>
      </section>
    </ConfirmationMessageContainer>
  );
};

export default Faild;
