import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

const ConfirmationMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Set the height to 100% of the viewport height for vertical centering */
  width: 100%; /* Set the width to 100% for responsiveness */

  p {
    text-align: center; /* Center the text horizontally */
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 20px; /* Add padding for better readability */
  }
`;

const Return = () => {
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState("");
  const navigate = useNavigate();

  const onSuccess = () => {
    navigate("/");
  };

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get("session_id");

    fetch(`/api/session-status?session_id=${sessionId}`)
      .then((res) => res.json())
      .then((data) => {
        console.log("sessionconsole", data);
        setStatus(data.status);
        setCustomerEmail(data.customer_email);
      });
  }, []);

  if (status === "open") {
    return <Navigate to="/faild" />;
  }

  if (status === "complete") {
    return (
      <ConfirmationMessageContainer>
        <section id="success">
          <p
            style={{
              boxShadow: " rgba(0,0,0,0.24) 0px 3px 8px",
              maxWidth: "500px",
            }}
          >
            <div>
              We appreciate your business! A confirmation email will be sent to{" "}
              {customerEmail}. If you have any questions, please email{" "}
              <a href="mailto:iinfo@myoffshorebusiness.net">
                info@myoffshorebusiness.net
              </a>
              .
            </div>
            <Button
              className="mt-4"
              style={{ backgroundColor: "#29bcb0", border: "none" }}
              onClick={() => onSuccess()}
            >
              Success
            </Button>
          </p>
        </section>
      </ConfirmationMessageContainer>
    );
  }

  return null;
};

export default Return;
