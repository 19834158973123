import { useContext, useEffect, Suspense } from "react";
import Landing from "./pages/Landing";
import ThemeContext from "./contexts/ThemeContext";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./styles/GlobalStyles";
import "./App.css";
import "./i18n";
import { useTranslation } from "react-i18next";
import PreCheckoutForm from "./pages/PreCheckoutForm";
import CheckoutForm from "./components/Stripe/CheckoutForm";
import Return from "./components/Stripe/Return";
import Faild from "./components/Stripe/Faild";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    fetch("https://ipapi.co/json/")
      .then((response) => response.json())
      .then((data) => {
        const country = data.country_code;
        const continent = data.continent_code;
        localStorage.setItem("MOFB_Continent", continent);
        if (country === "DE") {
          i18n.changeLanguage("de");
          localStorage.setItem("i18nextLng", "de");
        } else if (country === "BR" || country === "PT") {
          i18n.changeLanguage("pt");
          localStorage.setItem("i18nextLng", "pt");
        } else if (country === "IT") {
          i18n.changeLanguage("it");
          localStorage.setItem("i18nextLng", "it");
        } else if (
          country === "RU" ||
          country === "KG" ||
          country === "KZ" ||
          country === "BY"
        ) {
          i18n.changeLanguage("ru");
          localStorage.setItem("i18nextLng", "ru");
        } else if (country === "ES") {
          i18n.changeLanguage("es");
          localStorage.setItem("i18nextLng", "es");
        } else {
          i18n.changeLanguage("en");
          localStorage.setItem("i18nextLng", "en");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [i18n]);
  const { theme } = useContext(ThemeContext);
  return (
    <>
      <Suspense fallback="loading">
        <ThemeProvider theme={{ theme }}>
          <GlobalStyles />
          <Router>
            <Routes>
              <Route path="/" element={<Landing />} />
              <Route path="/faild" element={<Faild />} />
              <Route
                path="/precheckoutform/:productID"
                element={<PreCheckoutForm />}
              />
              <Route path="/checkout/:productID" element={<CheckoutForm />} />
              <Route path="/return" element={<Return />} />
            </Routes>
          </Router>
        </ThemeProvider>
      </Suspense>
    </>
  );
}

export default App;
