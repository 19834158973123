import styled from "styled-components";
import { themeList } from "../../contexts/ThemeContext/themeList";

export const PricingCardStyled = styled.div`
  padding: 15px;
  border-radius: 10px;
  background-color: ${(props) =>
    props.bloodState
      ? "var(--ds-green-1)"
      : themeList.light === props.theme
      ? "var(--ds-light-0)"
      : "var(--ds-dark-1)"};
  h3 {
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 10px;
    color: ${(props) =>
      props.bloodState
        ? "var(--ds-light-0)"
        : themeList.light === props.theme
        ? "var(--ds-dark-1)"
        : "var(--ds-light-0)"};
  }
  h4 {
    padding: 10px;
    sup {
      color: ${(props) =>
        props.bloodState ? "var(--ds-light-0)" : "var(--ds-green-1)"};
      font-size: 20px;
    }
    sub {
      color: ${(props) =>
        props.bloodState
          ? "var(--ds-light-0)"
          : themeList.light === props.theme
          ? "var(--ds-dark-1)"
          : "var(--ds-light-0)"};
      font-size: 18px;
      font-weight: 300;
    }
    span {
      font-size: 40px;
      font-weight: 400;
      color: ${(props) =>
        props.bloodState ? "var(--ds-light-0)" : "var(--ds-green-1)"};
    }
  }
  ul {
    li {
      padding: 8px;
      font-size: 15px;
      font-weight: 400;
      color: ${(props) =>
        props.bloodState
          ? "var(--ds-light-0)"
          : themeList.light === props.theme
          ? "var(--ds-dark-1)"
          : "var(--ds-light-0)"};
      &:nth-of-type(4) {
        text-decoration: ${(props) =>
          props.planType === "free" && "line-through"};
      }
      &:nth-of-type(5) {
        text-decoration: ${(props) =>
          (props.planType === "free" || props.planType === "business") &&
          "line-through"};
      }
    }
  }
  &:hover {
    background-color: var(--ds-green-1);
    h3 {
      color: white;
    }
    h4 {
      sub {
        color: var(--ds-light-0);
      }
      sup {
        color: white;
      }
      span {
        color: white;
      }
    }
    button {
      background-color: var(--ds-light-0);
      color: var(--ds-green-1);
    }
    ul {
      li {
        color: white;
      }
    }
  }
`;
