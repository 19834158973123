import React from "react";
// import {AnimationOnScroll} from "react-animation-on-scroll";
import { Col, Row, Form } from "react-bootstrap";
import CustomButton from "../../components/CustomButton";
import { FormStyled } from "./contact.styled";
// import { useForm } from "@formspree/react";
import { useTranslation } from "react-i18next";

const ContactMapFormBlock = () => {
  return (
    <Row>
      {/* <Col lg={6} className="my-2">
          <Map />
        </Col> */}
      <Col className="my-2">
        {/* <AnimationOnScroll animateIn={`${"animate__fadeIn"}`}> */}
        <ContactForm />
        {/* </AnimationOnScroll> */}
      </Col>
    </Row>
  );
};

// function Map() {
//   return (
//     <div className="google-map">
//       <div
//         className="mapouter"
//         style={{
//           position: "relative",
//           textAlign: "right",
//           minHeight: "300px",
//           width: "100%",
//         }}
//       >
//         <div
//           className="gmap_canvas"
//           style={{
//             overflow: "hidden",
//             background: " none !important",
//             height: "100%",
//             width: "100%",
//           }}
//         >
//           <iframe
//             title="This is a unique title"
//             width="100%"
//             height="100%"
//             id="gamp_canvas"
//             src="https://maps.google.com/maps?q=2880%20Broadway,%20New%20York&t=&z=11&ie=UTF8&iwloc=&output=embed"
//             style={{minHeight: "496px"}}
//           />
//         </div>
//       </div>
//     </div>
//   );
// }
function ContactForm() {
  const { t } = useTranslation();

  // const [state, handleSubmit] = useForm("mqkrzbjw");
  const [sent, setSent] = React.useState(false);
  const [error, setError] = React.useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    data.append("service_id", "service_mofb");
    data.append("template_id", "template_mofb");
    data.append("user_id", "tFz4wyzIB6XoIlfv7");
    const resp = await fetch(
      "https://api.emailjs.com/api/v1.0/email/send-form",
      {
        method: "POST",
        body: data,
      }
    );
    if (resp.ok) {
      setSent(true);
    } else {
      console.error("Error sending email", await resp.text());
      setError("Error sending email");
    }
  };

  if (error) {
    return <p>There was an error sending your message. Please try again.</p>;
  }

  if (sent) {
    // return <p>Thanks for joining!</p>;
    console.log("Okay");
    return (
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          width: "100%",
        }}
      >
        <h3>
          Thanks for contacting us! We will get back to you via email shortly.
        </h3>
      </Row>
    );
  }

  return (
    <FormStyled onSubmit={handleSubmit}>
      <Row>
        <Col lg={6}>
          <Form.Control
            size="lg"
            type="text"
            placeholder="Your Name"
            name="name"
            id="full-name"
            required=""
          />
        </Col>
        <Col lg={6}>
          <Form.Control
            size="lg"
            type="email"
            placeholder="Your Email"
            name="_replyto"
            id="email-address"
            required=""
          />
        </Col>
        <Col lg={12}>
          <Form.Control
            size="lg"
            type="text"
            placeholder="Subject"
            name="subject"
            id="subject"
            required=""
          />
        </Col>
        <Col lg={12}>
          <Form.Control
            as="textarea"
            rows={4}
            placeholder="Message"
            name="message"
            id="message"
            required=""
          />
        </Col>
      </Row>
      <div className="text-center">
        <CustomButton className="my-3" type={"regular-pricing-btn"}>
          {t("Send Message")}
        </CustomButton>
      </div>
    </FormStyled>
  );
}

export default ContactMapFormBlock;
