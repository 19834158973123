import React, { useEffect, useState } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { HiOutlineMenu } from "react-icons/hi";
// import { MdClose } from "react-icons/md";
import {
  HeaderStyled,
  CustomDropdown,
  CustomDropdownToggle,
  CustomDropdownMenu,
  FlagImage,
  StyledDropdownItem,
} from "./header.styled";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import Logo from "../../assets/Logo.png";
import US from "../../assets/us.png";
import ES from "../../assets/es.png";
import PT from "../../assets/pt.png";
import DE from "../../assets/de.png";
import IT from "../../assets/it.png";
import RU from "../../assets/ru.png";
import ToggleThemeButton from "../../components/ToggleThemeButton/index";
import styled, { css } from "styled-components";
import { themeList } from "../../contexts/ThemeContext/themeList";
// import Dropdown from "react-bootstrap/Dropdown";

const people = [
  { name: "English", img: US, lang: "en" },
  { name: "Español", img: ES, lang: "es" },
  { name: "Português", img: PT, lang: "pt" },
  { name: "Deutsch", img: DE, lang: "de" },
  { name: "Italiano", img: IT, lang: "it" },
  { name: "русский", img: RU, lang: "ru" },
];

const navlinks = [
  "Home",
  // "About",
  "Services",
  // "Portfolio",
  "Pricing",
  // "Team",
  "Contact",
];

// const Nav1 = styled.nav`
//   &.ms-auto {
//     margin-left: auto;
//   }
//   &.d-flex {
//     display: flex;
//   }
//   &.d-lg-none {
//     display: none;
//   }
//   &.ps-3 {
//     padding-left: 0.75rem;
//   }
// `;

const MenuIcon = styled(HiOutlineMenu)`
  &.menu-icon {
    // Add your styles for the menu icon here
  }
`;

const NavLinksContainer = styled.div`
  ${({ isOpen }) =>
    isOpen &&
    css`
      position: related;
      // margin-top: 40px;
      // margin-right" 125px;
      right: 20px;
      display: flex;
      flex-direction: column;
      color: ${({ theme: { theme } }) =>
        theme === themeList.light ? "var(--ds-dark-1)" : "var(--ds-light-0)"};
      border-radius: var(--bs-dropdown-border-radius);
    `}
`;

const HoverDiv = styled.div`
  py: 1;
  color: black;
  &:hover {
    background-color: var(--ds-light-5);
    color: black;
  }
`;

const NavLink = styled(Link)`
  &.py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  &.px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  color: black;
  background-color: ${({ theme: { theme } }) =>
    theme === themeList.light ? "var(--ds-light-7)" : "var(--ds-dark-2)"};
  cursor: pointer;
`;

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [navBarScrolled, setNavBarScrolled] = useState(false);
  const handleResize = () => {
    if (window.innerWidth >= 992) {
      setIsOpen(false);
    }
  };
  const changeBackground = () => {
    if (window.scrollY >= 50) {
      setNavBarScrolled(true);
    } else {
      setNavBarScrolled(false);
    }
  };
  const { t } = useTranslation();
  // const [selectedPerson, setSelectedPerson] = useState(people[0]);

  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("i18nextLng") || "en"
  );

  const handleLanguageChange = (e) => {
    // console.log("language", e.target.value)
    // const selectedLang = e.target.value;
    // setSelectedPerson(people.find(person => person.lang === e))
    setSelectedLanguage(e);
    i18n.changeLanguage(e);
    localStorage.setItem("i18nextLng", e); // Set the selected language in local storage
  };

  useEffect(() => {
    // Your existing code for resize and scroll event listeners
    // ...

    fetch("https://ipapi.co/json/")
      .then((response) => response.json())
      .then((data) => {
        const country = data.country_code; // Use "country_code" for the country code
        console.log(country);
        switch (country) {
          case "DE":
            setSelectedLanguage("de");
            break;
          case "IT":
            setSelectedLanguage("it");
            break;
          case "BR":
          case "PT":
            setSelectedLanguage("pt");
            break;
          case "RU":
          case "KG":
          case "KZ":
          case "BY":
            setSelectedLanguage("ru");
            break;
          default:
            setSelectedLanguage("en");
            break;
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    //Removing the event listener will make the component more robust, make the app quicker, and reduce memory leaks.
    // -If the computations were more expensive, that'd be wasted CPU time on a component that doesn't exist in the DOM; better to remove the need for the calculations entirely
    // -If the added listener happens to fire extremely rapidly (like scroll), removing the listener could help performance, especially if there are multiple components like these
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", changeBackground);
    return () => {
      window.removeEventListener("scroll", changeBackground);
    };
  });
  return (
    <HeaderStyled className={navBarScrolled ? "active" : ""}>
      <Navbar>
        <Container>
          <Link to="home" smooth>
            {/* <Navbar.Brand>DAY</Navbar.Brand> */}
            <img
              src={Logo}
              alt="Logo"
              style={{
                width: "100px",
                cursor: "pointer",
              }}
            />
          </Link>
          <div className="d-flex">
            <div className="d-none d-lg-block">
              <Nav
                // className={`ms-auto${isOpen ? " nav-links-container" : ""}`}
                className="ms-auto"
              >
                {/* <MdClose
                className="d-block d-lg-none close-icon"
                color="red"
                onClick={() => setIsOpen(!isOpen)}
              /> */}
                {navlinks.map((link, idx) => (
                  <Link
                    key={idx}
                    to={`${link.toLowerCase()}`}
                    smooth
                    offset={-40}
                    duration={250}
                    spy
                    activeClass="active"
                    onClick={() =>
                      window.innerWidth <= 992 && setIsOpen(!isOpen)
                    }
                  >
                    {t(link)}
                  </Link>
                ))}
              </Nav>
            </div>
            <div className="d-block d-lg-none d-flex align-items-center">
              <CustomDropdown>
                <CustomDropdownToggle
                  id="dropdown-basic"
                  style={{ width: "60px", border: "none" }}
                  variant="default"
                >
                  <style>
                    {`
                   #dropdown-basic::after {
                   content: "";
                   display:none;
                    }
                  `}
                  </style>
                  <MenuIcon className="menu-icon m-0" />
                </CustomDropdownToggle>

                <CustomDropdownMenu className="text-center">
                  <NavLinksContainer>
                    {navlinks.map((link, idx) => (
                      <NavLink
                        key={idx}
                        to={`${link.toLowerCase()}`}
                        smooth
                        offset={-40}
                        duration={250}
                        spy
                        activeClass="active"
                        onClick={() =>
                          window.innerWidth <= 992 && setIsOpen(!isOpen)
                        }
                      >
                        <HoverDiv className="py-1">{t(link)}</HoverDiv>
                      </NavLink>
                    ))}
                  </NavLinksContainer>
                </CustomDropdownMenu>
              </CustomDropdown>
            </div>
            <ToggleThemeButton />

            <CustomDropdown className="py-2">
              <CustomDropdownToggle
                id="dropdown-basic"
                className="dropdown_toggle"
                variant="default"
              >
                <FlagImage
                  src={`../../assets/${selectedLanguage
                    .slice(-2)
                    .toLowerCase()}.png`}
                  className="me-2"
                />
                {
                  people.find((person) => person.lang === selectedLanguage)
                    ?.name
                }
              </CustomDropdownToggle>

              <CustomDropdownMenu>
                {people.map((person, personIdx) => (
                  <StyledDropdownItem
                    key={personIdx}
                    onClick={() => handleLanguageChange(person.lang)}
                  >
                    <FlagImage
                      src={person.img}
                      alt={person.name}
                      className="me-2"
                    />
                    {person.name}
                  </StyledDropdownItem>
                ))}
              </CustomDropdownMenu>
            </CustomDropdown>
            {/* <Nav className="ms-auto d-flex d-lg-none ps-3">
              <HiOutlineMenu
                className="menu-icon"
                onClick={() => setIsOpen(!isOpen)}
              />
              {isOpen && <NavContainer  className="d-flex flex-column" style = {{position: "fixed", marginTop: "40px", right: "20px"}}>{navlinks.map((link, idx) => (
                <Link
                  key={idx}
                  to={`${link.toLowerCase()}`}
                  smooth
                  offset={-40}
                  duration={250}
                  spy
                  activeClass="active"
                  onClick={() => window.innerWidth <= 992 && setIsOpen(!isOpen)}
                  className="py-1 px-1"
                >
                  {t(link)}
                </Link>
              ))}</NavContainer >}
            </Nav> */}
            {/* <Nav1 className="ms-auto d-flex d-lg-none ps-3">
              <MenuIcon className="menu-icon" onClick={() => setIsOpen(!isOpen)} />
              {isOpen &&
                <NavLinksContainer isOpen={isOpen}>
                  {navlinks.map((link, idx) => (
                    <NavLink
                      key={idx}
                      to={`${link.toLowerCase()}`}
                      smooth
                      offset={-40}
                      duration={250}
                      spy
                      activeClass="active"
                      onClick={() => window.innerWidth <= 992 && setIsOpen(!isOpen)}
                      className="py-1 px-1"
                    >
                      {t(link)}
                    </NavLink>
                  ))}
                </NavLinksContainer>
              }
            </Nav1> */}
          </div>
        </Container>
      </Navbar>
    </HeaderStyled>
  );
};
export default Header;
