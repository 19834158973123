import React from "react";
// import {AnimationOnScroll} from "react-animation-on-scroll";
import {Col, Row} from "react-bootstrap";
import CardItem from "../../components/CardItem";
import {services} from "./servicesData";
import { useTranslation } from "react-i18next";
// import i18n from "i18next";

const ServicesBlock = () => {
  const { t } = useTranslation();

  return (
    <div className="services-block">
      <Row>
        {services.map(({iconName, title, text}, idx) => (
          <Col md={6} lg={4} className="mb-2" key={idx}>
            {/* <AnimationOnScroll animateIn="animate__fadeIn"> */}
              <CardItem
                alignStateCenter={true}
                Icon={iconName}
                cardTitle={t(title)}
                cardText={t(text)}
              />
            {/* </AnimationOnScroll> */}
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default ServicesBlock;
