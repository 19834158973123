import React from "react";
import { Container } from "react-bootstrap";
import CustomButton from "../../components/CustomButton";
import { HomeSectionStyled } from "./home.styled";
import homeImg from "../../assets/home-bg5.webp";
import Header from "../../Layout/Header";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { t } = useTranslation();

  return (
    <HomeSectionStyled
      id={"home"}
      className="home-sec d-flex align-items-center"
      style={{
        background: `url(${homeImg}) top center no-repeat`,
        height: "calc(100vh)",
        backgroundSize: "cover",
        // backgroundAttachment: "fixed",
      }}
    >
      <div
        className="overlay"
        style={{
          background: "radial-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0.7))",
          height: "calc(100vh)",
          width: "100%",
          position: "absolute",
          top: 0,
          left: 0,
        }}
      ></div>
      <Header />
      <Container className="home-container animate__animated animate__lightSpeedInLeft">
        <h1
          style={{
            fontFamily: "Inter, sans-serif",
          }}
        >
          {t("WELCOME TO MOFB")}
        </h1>
        {/* <h2>
          We are team of talented designers making websites with Bootstrap
        </h2> */}
        <a href="#contact" style={{ marginTop: "1rem" }}>
          <CustomButton type="btn-on-img">{t("GET STARTED")}</CustomButton>
        </a>
        {/* <Wrap class="wrap">
  <Button class="button">Submit</Button>
</Wrap> */}
      </Container>
    </HomeSectionStyled>
  );
};

export default Home;
